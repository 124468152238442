.git_wrapper {
  /* max-width: 1920px; */
  max-width: 1280px;
  margin: auto;
}

/* .git_main {
    background: rgb(239, 246, 255);
    background: radial-gradient(circle, rgba(239, 246, 255, 0.821187850140056) 0%, rgba(0, 0, 0, 0.0032606792717086996) 94%, rgba(0, 0, 0, 0.0032606792717086996) 100%);
    background: rgb(239, 246, 255);
    background: radial-gradient(circle, rgba(239, 246, 255, 0.821187850140056) 20%, rgba(0, 0, 0, 0.0032606792717086996) 95%);
    background: rgb(0, 0, 0);
    background: linear-gradient(300deg, rgba(0, 0, 0, 0.0032606792717086996) 0%, rgba(239, 246, 255, 0.821187850140056) 58%, rgba(0, 0, 0, 0.0032606792717086996) 100%);
} */

.git_title {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  margin: 96px 0 80px 0;
}

.git_title h1 {
  text-align: center;
  grid-column-start: 8;
  grid-column-end: 15;
  color: rgba(28, 28, 35, 1);
  padding-bottom: 24px;

  font-family: "Space Grotesk";
  font-size: 64px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: -0.02em;
  text-align: center;
}

.git_title h2 {
  text-align: center;
  grid-column-start: 3;
  grid-column-end: 20;
  color: rgba(25, 25, 25, 0.6);

  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -1px;
  text-align: center;
}

.git_section {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
}

.git_sectionMain {
  grid-column-start: 2;
  grid-column-end: 21;
  display: grid;
  grid-template-columns: repeat(21, 1fr);
}

.git_sectionMain {
  grid-column-start: 1;
  grid-column-end: 22;
  display: flex;
  gap: 10px;
  justify-content: space-around;
}

.git_sectionMainLeft {
  display: flex;
  flex-direction: column;
}

.git_sectionMainLeft input {
  width: 600px;
  padding: 24px;
  background: #e5eefc;
  border: #f3f8ff;
  outline: none;
  margin: auto;
}

.git_sectionMainLeft input::placeholder {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: rgba(25, 25, 25, 0.3);
}

.git_sectionMainLeft input[type="checkbox" i] {
  width: unset;
  margin-top: 25px;
}

.git_sectionMainRight {
  margin: 0 auto;
}

.git_sectionMainRight textarea {
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: rgba(25, 25, 25, 0.3);
  resize: none;
  background: #e5eefc;
  width: 600px;
  height: 100%;
  padding: 20px;
  border: #e5eefc;
  color: var(--color-black);
}

.git_section span {
  grid-column-start: 1;
  grid-column-end: 21;
  padding-top: 47px;
  /* margin-left: -20px; */
}

.git_section span > * + * {
  margin-left: 1rem;
}

.git_section .submission {
  grid-column-start: 18;
  grid-column-end: 22;
  padding-bottom: 142px;
}

.submission button {
  padding: 16px 32px;
  background: var(--color-blue);
  border-radius: 50px;
  color: white;
  border: var(--color-blue);
}

.disabled {
  cursor: not-allowed !important;
  padding: 16px 32px;
  background: var(--color-white) !important;
  border-radius: 50px;
  color: var(--color-blue) !important;
  border: 1px solid var(--color-blue) !important;
}
.mobileNews {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
}

/* NEWSLETTER SECTION */

.newsletter_wrapper {
  grid-column-start: 2;
  grid-column-end: 21;
  background-color: var(--color-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 96px;
}

.newsletter_wrapper h1 {
  font-family: "Space Grotesk";
  font-size: 20px;
  line-height: 29px;
  padding-top: 32px;
  text-align: center;
  color: #ebf1fc;
  padding-bottom: 8px;
  margin-bottom: 0px;
}

.newsletter_wrapper h2 {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 173.66%;
  text-align: center;
  color: #ebf1fc;
  padding-bottom: 8px;
}

.newsletter_button {
  display: flex;
  /* gap: 8px; */
  padding-bottom: 64px;
  /* width: calc(100% - 20vw); */
  width: 600px;
}
/* owl selector used in place of gap as safari can't read it */
.newsletter_button > * + * {
  margin-left: 8px;
}

.newsletter_button input {
  width: 560px;
  background: #f6f6f8;
  border-radius: 4px;
  border: var(--color-blue);
  padding: 7px 10px;
  outline: var(--color-blue);
}

.newsletter_button button {
  padding: 14px 16px;
  background: #ffffff;
  border-radius: 4px;
  color: var(--color-blue);
  border: var(--color-blue);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.gitarea {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  max-width: 1920px;
  margin: auto;
  padding-bottom: 66px;
  /* padding-top: 112px; */
}
.gitbox {
  display: flex;
  grid-column-start: 2;
  grid-column-end: 21;
}
.gitbox::after {
  content: url(../../assets/images/gite.svg);
  margin-left: -114px;
  margin-bottom: -6px;
  padding-top: 32px;
}

@media screen and (max-width: 1280px) {
  .git_sectionMain {
    grid-column-start: 2;
    grid-column-end: 22;
  }

  .git_sectionMainRight textarea {
    width: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .git_sectionMain {
    grid-column-start: 1;
    grid-column-end: 22;
  }

  .git_title {
    display: grid;
    grid-template-columns: repeat(21, 1fr);
  }

  .git_title h1 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #121212;
    grid-column-start: 7;
    grid-column-end: 16;
  }

  .git_title h2 {
    grid-column-start: 3;
    grid-column-end: 20;
    font-size: 14px;
  }

  .git_sectionMain {
    gap: 22px;
    flex-direction: column;
  }

  .git_sectionMainLeft input {
    width: 90vw;
  }

  .git_sectionMainRight textarea {
    height: 32vh;
    width: 90vw;
  }

  .git_section span {
    grid-column-start: 2;
    grid-column-end: 23;
    padding-top: 16px;
  }

  .git_section span > * + * {
    margin-left: 1rem;
    font-size: 14px;
  }

  .git_section .submission {
    grid-column-start: 2;
    grid-column-end: 21;
    padding-bottom: 64px;
    padding-top: 40px;
  }

  .submission button {
    width: 100%;
  }

  .mobileNews {
    display: grid;
    grid-template-columns: repeat(21, 1fr);
  }

  .newsletter_wrapper {
    grid-column-start: 2;
    grid-column-end: 21;
  }
}
@media screen and (max-width: 820px) {
  .newsletter_button {
    width: 400px;
  }
}

@media screen and (max-width: 560px) {
  .gitbox::after {
    content: url(../../assets/images/gite.svg);
    margin-left: -114px;
    margin-bottom: -6px;
    padding-top: 32px;
    visibility: hidden;
  }
  .newsletter_button {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .newsletter_button {
    width: 280px;
  }
}
