*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk";
}
html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color-lightBlue: #eff6ff;
  --color-blue: #004ebb;
  --color-black: #191919;
  --color-gray: rgba(25, 25, 25, 0.7);
  --color-grey: #595959;
  --color-white: #fff;
}

a {
  text-decoration: unset !important;
  color: var(--color-gray) !important;
}

ul {
  padding-left: 0px !important;
}

p {
  margin-bottom: 0px;
}

*::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Space Grotesk";
  src: url(./assets/fonts/Segoe\ UI.ttf);
}

@font-face {
  font-family: "Space Grotesk";
  src: url(./assets/fonts/Segoe\ UI\ Bold.ttf);
}

a {
  list-style: none;
  text-decoration: none;
  color: var(--color-grey);
}

.unclicked {
  background-color: white;
  color: var(--color-blue);
  padding: 14px 16px;
  cursor: pointer;
  border: 1px solid var(--color-blue);
  border-radius: 38px;
}

.clicked {
  background-color: var(--color-blue);
  color: #fff;
  padding: 14px 16px;
  cursor: pointer;
  border: 1px solid var(--color-blue);
  border-radius: 38px;
}

.w-100 {
  width: 455px !important ;
  max-height: 500px !important;
  min-width: 450px !important;
}
/* carousel stylings */

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.carousel-caption {
  right: 0 !important;
  left: 0 !important;
  padding: 90px 20px 180px 20px !important;
}
.carousel-indicators {
  display: flex !important;
}

.carousel-indicators [data-bs-target] {
  display: none !important;
}
.offcanvas-title {
  left: calc(50% - 4vw) !important;
  position: relative !important;
}
.loading {
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  background-color: #004ebb;
  border-radius: 100%;
  height: 6em;
  width: 6em;
}

.container {
  align-items: center;
  background-color: white;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
    transform: scale(1);
  }
}

@media screen and (max-width: 414px) {
  .carousel-caption {
    padding: 90px 20px 90px 20px !important;
  }
}

@media screen and (max-width: 393px) {
  .carousel-caption {
    padding: 90px 20px 70px 20px !important;
  }
}

@media screen and (max-width: 373px) {
  .carousel-caption {
    padding: 90px 20px 70px 20px !important;
  }
}

@media screen and (max-width: 360px) {
  .carousel-caption {
    padding: 90px 20px 60px 20px !important;
  }
}

@media screen and (max-width: 300px) {
  .carousel-caption {
    padding: 90px 20px -20px 20px !important;
    margin-bottom: 20px !important;
  }
}
