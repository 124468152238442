.home_main {
  /* max-width: 2160px; */
  max-width: 1920px;
  margin: auto;
  /* max-height: 1920px; */
  /* max-width: 1280px; */
}

.home {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.herosection {
  grid-column-start: 1;
  grid-column-end: 18;
  padding: 0px 30px;
}

.hero_container {
  position: relative;
  height: 78vh;
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.6) 33.42%,
    rgba(0, 0, 0, 0) 105.12%
  );
}

.hero_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(37, 37, 37, 0.7); */
  opacity: 1;
}
video,
.thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  /* transform: scale(1); */
  transition: visibility 0ms ease 400ms;
}

.hero_content {
  grid-column-start: 1;
  grid-column-end: 22;
  z-index: 1;
  overflow-y: auto;
  max-height: 100%;
  /* flex: 1; */

  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.vanishhead {
  position: relative;
}
.vanish {
  height: 30vh;

  position: sticky;
  z-index: 90;
  opacity: 0;
}
.Content1,
.Content2 {
  grid-column-start: 2;
  grid-column-end: 12;
  padding-bottom: 25vh;
}
.Content1 button {
  background-color: var(--color-blue);
  padding: 18px 20px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #fff;
  border: none;
}
.Content2Img {
  display: flex;
  gap: 20px;
}
.Content2Img img {
  max-width: 75px;
  max-height: 60px;
}

.arrowimg img {
  max-width: 44px;
  max-height: 44px;
}

.arrowimg {
  grid-column-start: 2;
  grid-column-end: 12;
  position: absolute;
  left: 43vw;
  bottom: 40px;
}

.hero_content h1 {
  margin-bottom: 0px;
  font-size: 47px;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffffff;
}
.hero_content h2 {
  color: #fff;
  padding-top: 16px;
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: left;
}
.herodemo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.herodemo img {
  max-width: 20px;
}
.herodemo p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  margin-bottom: 0px;
  text-align: left;
}

.herodemo > * + * {
  margin-left: 10px;
}
@media screen and (min-width: 2000px) {
  .Content1,
  .Content2 {
    padding-bottom: 42vh;
  }
}

@media screen and (max-width: 1710px) {
  .hero_content {
    grid-column-end: 22;
  }
}
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1400px) {
  .hero_content {
    grid-column-end: 22;
  }
}
@media screen and (max-width: 1300px) {
  .hero_content h1 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 1200px) {
  .hero_content h1 {
    font-size: 40px;
    line-height: 45px;
  }
  .hero_content h2 {
    font-size: 1.2em;
  }
  .herodemo p {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 1180px) {
  .hero_content {
    grid-column-end: 22;
  }
}
@media screen and (max-width: 1035px) {
  .hero_content h1 {
    font-size: 1.9em;
  }

  .hero_content h2 {
    font-size: 16px;
    padding-top: 10px;
    line-height: 27px;
  }
  .herodemo p {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 912px) {
  .hero_content {
    grid-column-start: 2;
  }
}
@media screen and (max-width: 885px) {
  .hero_content h1 {
    font-size: 1.75em;
  }
  .hero_content h2 {
    font-size: 0.99em;
    padding-top: 0px;
    padding-bottom: 8px;
  }
  .herodemo p {
    font-size: 13.2px;
  }
  .herodemo img {
    max-width: 18px;
  }
  .Content1,
  .Content2 {
    padding-top: 20vh;
  }
}

@media screen and (max-width: 795px) {
  .hero_content {
    grid-column-end: 22;
  }
}

@media screen and (max-width: 560px) {
  .hero_content {
    grid-column-end: 22;
  }

  .hero_content h1 {
    font-size: 1.49em;
  }
  .hero_content h2 {
    font-size: 0.9em;
  }
  .Content2Img {
    flex-direction: column;
  }
  .herosection {
    padding: 0px 0px;
  }
}
@media screen and (max-width: 375px) {
  .hero_content {
    grid-column-end: 23;
  }
}
