.Collab {
  max-width: 1920px;
  margin: auto;
  background-color: #1f233e;
}

.CollabContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 0px 117px 0px;
}
.CollabContent h1 {
  font-size: 54px;
  font-weight: 500;
  line-height: 92px;
  letter-spacing: -0.06em;
  text-align: left;
  color: var(--color-white);
}
.CollabContent button {
  padding: 19px 48px;
  background-color: #004ebb;
  color: var(--color-white);
  border: none;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

@media screen and (max-width: 530px) {
  .CollabContent h1 {
    font-size: 40px;
    text-align: center;
    line-height: 50px;
    padding-bottom: 20px;
  }
}
