.About {
  max-width: 1920px;
  margin: auto;
}

/* carry  */
.ProdTop {
  background-color: var(--color-blue);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  text-align: center;
  padding: 70px 0px 110px 0px;
  color: var(--color-white);
}
.ProdTop h1 {
  grid-column-start: 5;
  grid-column-end: 9;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.06em;
  padding-bottom: 22px;
}
.ProdTop h2 {
  grid-column-start: 4;
  grid-column-end: 10;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}
.ProdSpan {
  color: #ffe500;
}
/* to here */

.ProdMain {
  background-color: #f1f7ff;
  display: flex;
  flex-direction: column;
  padding-bottom: 88px;
}

.ProdMainText {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.ProdMainText h1 {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;
  padding-top: 35px;
  font-size: 54px;
  font-weight: 500;
  line-height: 92px;
  letter-spacing: -0.06em;
}
.ProdMainText h2 {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #1f233e;
}
.Images {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}

.Images .left {
  grid-column-start: 2;
  grid-column-end: 7;
}

.Images .mid {
  grid-column-start: 2;
  grid-column-end: 12;
}
.Images .right {
  grid-column-start: 7;
  grid-column-end: 12;
}
.ProdImg {
  margin-top: 44px;
}
.ProdImg img {
  max-width: 100%;
}

.Fianance {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.Finance h1 {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;
  padding-top: 49px;
  font-size: 54px;
  font-weight: 500;
  line-height: 92px;
  letter-spacing: -0.06em;
}
.Finance h2 {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  color: #1f233e;
  padding-bottom: 43px;
}

.ProdBot {
  padding-bottom: 130px;
}

.Testimonial {
  background-color: #1f233e;
  padding-top: 109px;
}

.Test {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  padding-bottom: 20px;
}
.TestLeft {
  grid-column-start: 2;
  grid-column-end: 6;
}
.TestLeft img {
  max-width: 100%;
  max-height: 100%;
}
.TestRight {
  grid-column-start: 6;
  grid-column-end: 10;
  background-color: var(--color-blue);
  height: 100%;
  padding: 0px 40px 0px 70px;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.TestRight h1 {
  font-size: 25px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
}
.TestRight h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 20px;
}

.TestRight h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;

  text-align: left;
}
.slidecontrol {
  display: flex;
  gap: 18px;
  padding-top: 35px;
  cursor: pointer;
  position: absolute;
  bottom: 35px;
}
.slidecontrol img {
  max-width: 8px;
}

.fadeInOut {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fadeOut {
  opacity: 0.8;
}

@media screen and (max-width: 1500px) {
  .TestRight h1 {
    font-size: 20px;
  }
  .TestRight h2 {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .TestRight h3 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1380px) {
  .TestRight {
    padding: 0px 20px 0px 30px;
  }
  .slidecontrol {
    bottom: 20px;
  }
}
@media screen and (max-width: 1260px) {
  .TestRight h1,
  .TestRight h2,
  .TestRight h3 {
    font-size: 16px;
  }
  .TestRight h1 {
    line-height: 25px;
  }
}

@media screen and (max-width: 1080px) {
  .TestRight h2 {
    padding-bottom: 0px;
  }
  .TestRight h1,
  .TestRight h2,
  .TestRight h3 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1052px) {
  .TestRight {
    grid-column-end: 12;
  }
  .TestLeft {
    grid-column-start: 1;
  }
  .Test {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 830px) {
  .TestRight h1,
  .TestRight h2,
  .TestRight h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .Test {
    display: flex;
    flex-direction: column;
  }
  .TestRight {
    grid-column-end: 12;
    padding: 41px;
  }
}

@media screen and (max-width: 768px) {
  .ProdMainText h1 {
    font-size: 48px;
  }
  .ProdTop h2 {
    grid-column-start: 2;
    grid-column-end: 12;
  }
  .ProdTop {
    padding: 70px 0px 70px 0px;
  }
  .ProdMainText h2 {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}

@media screen and (max-width: 540px) {
  .ProdMainText h1,
  .ProdTop h1 {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .Finance h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 540px) {
  .Images {
    gap: 35px;
  }
}
