.not_found {
    display: grid;

    grid-template-columns: repeat(11, 1fr);
    justify-content: center;
    align-items: center;
}

.not_found h1 {
    padding: 100px 0 40px 0px;
    grid-column-start: 2;
    grid-column-end: 11;

    text-align: center;
    justify-content: center;
    font-size: 40px;

}

.not_found svg {
    grid-column-start: 5;
    grid-column-end: 9;
    max-width: 400px;
}

.not_found h2 {
    padding: 16px 0px 32px 0px;
    grid-column-start: 4;
    grid-column-end: 9;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.16%;
    text-align: center;
    color: #595959;

}

.not_found button {
    grid-column-start: 6;
    grid-column-end: 7;
    color: white;
    background-color: var(--color-blue);
    border-radius: 50px;
    padding: 10px 21px;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    border: var(--color-blue);
    cursor: pointer;
    justify-self: center;
}

@media screen and (max-width: 912px) {
    .not_found button {
        grid-column-start: 5;
        grid-column-end: 8;
    }
    .not_found svg {
        grid-column-start: 4;
        grid-column-end: 9;
        max-width: 400px;
    }
}