.navbar_main {
  background-color: #fff;
  top: 0;
  position: sticky;
  z-index: 9999999;
  border-bottom: 1px solid rgba(29, 25, 31, 0.1);
}

.navbar {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  padding-top: 1rem;
  align-items: center;
  padding-bottom: 1rem;
  max-width: 1920px;
  margin: auto;
}

.navbar_links {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0rem;
}

.navbar_links > * + * {
  margin-left: 2rem;
}

.navbar_link1 {
  margin-right: 8px;
}
.link a {
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -1px;
}

.navbar_content:nth-child(1) {
  grid-column-start: 2;
  grid-column-end: 7;
  display: flex;
}

.navbar_content:nth-child(2) {
  grid-column-start: 12;
  grid-column-end: 21;
}

.ActiveTab {
  color: var(--color-blue) !important;
  font-weight: 700;
}

.unclicked {
  background-color: var(--color-blue);
  color: white;
  padding: 9px 16px;
  cursor: pointer;
  border: 1px solid var(--color-blue);
  border-radius: 4px;
  font-size: 14px;
}

.clicked {
  background-color: var(--color-blue);
  color: #fff;
  padding: 9px 16px;
  cursor: pointer;
  border: 1px solid var(--color-blue);
  border-radius: 4px;
  font-size: 14px;
}

.navbars {
  display: none;
}
.canvasButton {
  padding: 10px 21px;
  background: var(--color-blue);
  border-radius: 8px;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-white);
  border: none;
}

@media screen and (max-width: 1281px) {
  .unclicked,
  .clicked {
    padding: 8px 14px;
  }
}

@media screen and (max-width: 1239px) {
  .navbar_content:nth-child(3) {
    grid-column-start: 18;
    grid-column-end: 21;
  }
}
@media screen and (max-width: 1040px) {

  
  .navbar_content:nth-child(2) {
    grid-column-start: 9;
    grid-column-end: 21;
}
}

@media screen and (max-width: 912px) {
  .navbar_content:nth-child(1) {
    grid-column-start: 2;
    /* grid-column-end: 14; */
    /* display: flex; */
  }
}


@media screen and (max-width: 820px) {
  .unclicked,
  .clicked {
    padding: 8px 12px;
  }
  .navbar_links > * + * {
    margin-left: 22px;
  }
}

@media screen and (max-width: 768px) {
  .unclicked,
  .clicked {
    padding: 8px 9px;
  }
}
@media screen and (max-width: 720px) {
  .link a {
    font-family: "Space Grotesk", sans-serif;
    font-size: 14px;
  }
  .navbar_content:nth-child(1) {
    grid-column-start: 2;
  }
}
@media screen and (max-width: 680px) {
  .unclicked,
  .clicked {
    padding: 3px 7px;
    font-size: 12px;
  }
  .navbar_links > * + * {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 590px) {
  .navbar {
    display: none;
  }
  .navbar_link1 {
    margin-right: 0px;
  }
  .navbars {
    display: grid;
    grid-template-columns: repeat(21, 1fr);
    padding: 16px 0;
    align-items: center;
    background: white;
    z-index: 999;
  }
  .navbar_content {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .navbar_content:nth-child(1) {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .navbar_content:nth-child(2) {
    grid-column-start: 19;
    grid-column-end: 22;
  }

  .navbar_main {
    position: unset;
  }

  .navbar_links {
    flex-direction: column;
    padding: 10px 0;
  }

  .link {
    padding: 20px 0;
  }

  .navbar_links > * + * {
    margin-left: 0rem;
    padding: 20px 0;
  }
}
