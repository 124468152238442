.ContactUs {
  background-color: var(--color-blue);
  padding-bottom: 95px;
  max-width: 1920px;
  margin: auto;
}

.contact {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.contact h1 {
  grid-column-start: 5;
  grid-column-end: 9;
  text-align: center;
  padding: 63px 0px 48px 0px;
  color: var(--color-white);
}

.contactform {
  grid-column-start: 5;
  grid-column-end: 9;
  background-color: var(--color-white);
  width: auto;
  padding: 22px 33px;
}
.formtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
}
.formChild {
  display: flex;
  flex-direction: column;
}
.formChild label {
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
}
.formChild input {
  margin-bottom: 34px;
  padding: 8px 12px;
}
.formChild input::placeholder {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
}
.formChild textarea {
  font-weight: 500;
  font-size: 16px;
  resize: none;
  height: 150px;
  padding: 8px 12px;
  margin-bottom: 41px;
}
.formChild button {
  padding: 23px 0px;
  border: none;
  color: var(--color-white);
  background-color: var(--color-blue);
  font-weight: 500;
  margin-bottom: 50px;
}
@media screen and (max-width: 1225px) {
  .contact h1,
  .contactform {
    grid-column-start: 4;
    grid-column-end: 10;
  }
}

@media screen and (max-width: 680px) {
  .contactform {
    width: max-content;
  }
}
