.Ignite {
  max-width: 1920px;
  margin: auto;
}
.IgniteTop {
  background-image: url("https://res.cloudinary.com/naijakids/image/upload/v1702859871/ignite01_kaybi2.png");
  height: 520px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.IgniteText {
  /* display: grid; */
  /* grid-template-columns: repeat(12, 1fr); */
  padding-top: 254px;
}
.IgniteText h1 {
  /* grid-column-start: 2; */
  /* grid-column-end: 12; */
  text-align: center;
  font-size: 95px;
  font-weight: 500;
  line-height: 92px;
  letter-spacing: -0.06em;
  color: var(--color-white);

  position: absolute;
  bottom: 160px;
  left: 25%;
}
.IgniteMain {
  background-color: #1f233e;
  /* height: 500px; */
  padding: 66px 0px;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
}
.IgniteLeft {
  grid-column-start: 2;
  grid-column-end: 5;
}
.IgniteMain h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 92px;
  letter-spacing: -0.06em;
  text-align: left;
  color: var(--color-white);
}
.IgniteMain h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;

  text-align: left;
  color: var(--color-white);
}
.IgniteMain img {
  max-width: 500px;
}
.IgniteRight {
  grid-column-start: 8;
  grid-column-end: 11;
}

.faq {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.faqName {
  grid-column-start: 2;
  grid-column-end: 9;
  text-align: left;
  padding-top: 66px;
}

.faqName h1 {
  font-size: 54px;
  font-weight: 500;
  line-height: 92px;
}
.faqName h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 41px;
}

.FaqWrap {
  grid-column-start: 2;
  grid-column-end: 12;
  margin-bottom: 90px;
}

.Wrapper {
  border: 1px solid #dbdbdb;
  padding: 20px;
  margin-bottom: 16px;
}
.Box {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.Box h1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
}

.Box h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
}
@media screen and (max-width: 1600px) {
  .IgniteRight {
    grid-column-start: 7;
    grid-column-end: 11;
  }
}
@media screen and (max-width: 1500px) {
  .IgniteText h1 {
    grid-column-start: 2;
    grid-column-end: 12;
    position: unset;
  }
  .IgniteText h1 {
    font-size: 80px;
  }
}
@media screen and (max-width: 1200px) {
  .IgniteMain img {
    max-width: 450px;
  }
  .IgniteLeft {
    grid-column-start: 2;
    grid-column-end: 6;
  }
}
@media screen and (max-width: 1000px) {
  .IgniteMain img {
    max-width: 380px;
  }
  .IgniteText h1 {
    font-size: 70px;
  }
  .IgniteTop {
    height: 430px;
  }
  .faqName h1 {
    font-size: 44px;
    font-weight: 500;
    line-height: 60px;
    grid-column-start: 2;
    grid-column-end: 10;
  }
}

@media screen and (max-width: 850px) {
  .IgniteMain img {
    max-width: 340px;
  }
  .faqName {
    grid-column-start: 2;
    grid-column-end: 11;
  }
}

@media screen and (max-width: 750px) {
  .IgniteLeft,
  .IgniteRight {
    grid-column-start: 2;
    grid-column-end: 11;
    padding: 15px 0px;
  }
  .IgniteMain h2,
  .IgniteMain h1 {
    text-align: center;
  }
  .faqName h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
  }
  .IgniteText {
    padding-top: 141px;
  }
  .IgniteText h1 {
    font-size: 65px;
    line-height: 64px;
}
}

@media screen and (max-width: 380px) {
  .IgniteLeft,
  .IgniteRight {
    grid-column-start: 1;
    grid-column-end: 12;
    padding-left: 40px;
    padding-right: 40px;
  }
  .IgniteMain img {
    max-width: 300px;
  }
}
