.About {
  max-width: 1920px;
  margin: auto;
}

/* carry  */
.ProdTop {
  background-color: var(--color-blue);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  text-align: center;
  padding: 70px 0px 90px 0px;
  color: var(--color-white);
}
.ProdTop h1 {
  grid-column-start: 5;
  grid-column-end: 9;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.06em;
  padding-bottom: 22px;
}
.ProdTop h2 {
  grid-column-start: 4;
  grid-column-end: 10;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}
.ProdSpan {
  color: #ffe500;
}
/* to here */

.AboutVision {
  background-color: #f7f7f7;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.VisionText {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 32px;
}

.VisionText h1 {
  font-size: 135px;
  font-weight: 500;
  line-height: 120px;
  letter-spacing: -0.06em;
}
.VisionText span {
  color: var(--color-blue);
}

.VisionMsg {
  grid-column-start: 2;
  grid-column-end: 12;
  background-color: var(--color-white);
  align-items: baseline;
  display: grid;
  grid-template-columns: 1fr, 1fr, 1fr;
  /* justify-content: space-between; */
  gap: 56px;
  padding: 64px 40px;
  margin-bottom: 118px;
}

.VisionMsg h1 {
  font-size: 44px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.01em;
  text-align: left;
}

.VisionMsg h2 {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;

  text-align: left;
  color: #666666;
}

.VisionMsg1 {
  grid-column-start: 1;
}
.VisionMsg2 {
  grid-column-start: 2;
}
.VisionMsg3 {
  grid-column-start: 3;
}

.SDGS {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.sdg {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 92px;
  letter-spacing: -0.06em;
  padding-top: 77px;
}
.spantext {
  color: #004ebb;
}

.sdg h2 {
  grid-column-start: 3;
  grid-column-end: 11;
}
.sdgimg {
  grid-column-start: 3;
  grid-column-end: 11;
  padding-top: 30px;
  padding-bottom: 100px;
}
.imgDiv {
  display: flex;
  justify-content: space-between;
}
.imgDiv img {
  max-width: 140px;
  /* max-height: 140px; */
}

.partner {
  grid-column-start: 3;
  grid-column-end: 11;
  text-align: center;
  padding-bottom: 77px;
}

.partner h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;

  text-align: center;
}

.sponsors {
  grid-column-start: 2;
  grid-column-end: 12;
  padding-bottom: 180px;
}
.sponsorIcons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mobile {
  display: none;
}
.sponsorIcons img {
  max-width: 176px;
  max-height: 38px;
}
.webimg {
  max-width: 100%;
}

@media screen and (max-width: 1300px) {
  .VisionMsg {
    gap: 25px;
  }
}
@media screen and (max-width: 1160px) {
  .VisionText {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .VisionText h1 {
    font-size: 120px;
  }
}
@media screen and (max-width: 1100px) {
  .imgDiv img {
    max-width: 110px;
  }
  .sdgimg {
    padding-top: 30px;
    padding-bottom: 70px;
  }
  .VisionMsg h1 {
    font-size: 34px;
    line-height: 40px;
  }
}

@media screen and (max-width: 820px) {
  .sdgimg {
    grid-column-start: 2;
    grid-column-end: 12;
  }
  .ProdTop h2 {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}
@media screen and (max-width: 777px) {
  .VisionMsg {
    display: flex;
    flex-direction: column;
  }
  .VisionText h1 {
    font-size: 100px;
  }
}
@media screen and (max-width: 580px) {
  .mobile {
    display: flex;
  }
  .webimg {
    display: none;
  }
  .sponsorIcons img {
    max-width: 100px;
  }
  .sponsorIcons {
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
  }
  .imgDiv img {
    max-width: 80px;
  }
}
@media screen and (max-width: 430px) {
  .imgDiv img {
    max-width: 65px;
  }
  .sdgimg {
    grid-column-start: 1;
    grid-column-end: 13;
    padding: 0px 10px;
  }
  .imgDiv {
    gap: 10px;
    padding-top: 20px;
  }
  .VisionText h1 {
    font-size: 70px;
  }
  .VisionMsg {
    padding: 50px 20px;
  }
  .VisionMsg h1 {
    font-size: 23px;
  }
  .VisionMsg h2 {
    font-size: 16px;
    line-height: 26px;
  }
  .ProdTop h2 {
    grid-column-start: 1;
    grid-column-end: 13;
    font-size: 18px;
    line-height: 30px;
  }
  .ProdTop h1 {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .partner h2 {
    font-size: 20px;
    line-height: 32px;
  }
  .partner {
    grid-column-start: 2;
    grid-column-end: 12;
  }
  .sponsors {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 380px) {
  .imgDiv {
    gap: 7px;
  }
}
