.foot {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: #fff;
  border-top: 1px solid rgba(29, 25, 31, 0.1);
  max-width: 1920px;
  margin: auto;
  padding: 43px 0px;
  background-color: #F7F7F7;
  height: 15vh;

  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;

}

.footlogo {
  grid-column-start: 2;
  grid-column-end: 6;
}
.footlogo img {
  max-width: 140px;
}

.smLinks {
  grid-column-start: 7;
  grid-column-end: 12;
  display: flex;
  justify-content: flex-end;
  gap: 65px;
}
.smLinks svg {
  min-width: 20px;
}
@media screen and (max-width: 680px) {
  .foot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
