.careers {
  max-width: 1920px;
  margin: auto;
}

.careerTop {
  background-color: var(--color-blue);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.careerTop h1 {
  grid-column-start: 5;
  grid-column-end: 9;
  text-align: center;

  font-size: 54px;
  font-weight: 500;
  line-height: 65px;
  letter-spacing: -0.01em;
  color: var(--color-white);
  padding: 84px 0px 18px 0px;
}
.careerTop h2 {
  grid-column-start: 4;
  grid-column-end: 10;
  text-align: center;
  color: var(--color-white);
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;

  padding-bottom: 41px;
  text-align: center;
}

.careerTop .image {
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.wrapper {
  background-color: #e5f0ff;
  padding-top: 5px;
}
.careerTop .image img {
  max-width: 700px;
}
.careerOpen {
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.careerOpen h1 {
  font-size: 54px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.01em;
  padding: 73px 0px 52px 0px;
  text-align: center;
}
.careerOpen h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 36px;

  text-align: center;
  color: #595959;
  padding: 42px 0px 21px 0px;
}

.careerOpen h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;

  text-align: center;
  padding-bottom: 32px;
}
.careercards {
  padding: 167px 0px 118px 0px;
}
.cardbox {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.card {
  width: 412px;
  height: 312px;
  background-color: #1f233e;
  border-radius: 14px;
  padding: 45px 25px;
}

.card h1 {
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--color-white);
  padding-bottom: 26px;
}
.card h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;

  color: var(--color-white);
}
@media screen and (max-width: 1059px) {
  .careerTop h1 {
    grid-column-start: 3;
    grid-column-end: 11;
    font-size: 44px;
    padding: 45px 0px 10px 0px;
  }
}

@media screen and (max-width: 709px) {
  .careerTop .image img {
    max-width: 600px;
  }
}

@media screen and (max-width: 609px) {
  .careerTop .image img {
    max-width: 400px;
  }
  .careerTop h1 {
    grid-column-start: 1;
    grid-column-end: 13;
    font-size: 38px;
  }
  .careerTop h2 {
    grid-column-start: 2;
    grid-column-end: 12;
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (max-width: 425px) {
  .careerTop .image img {
    max-width: 375px;
  }
  .card {
    width: 333px;
  }
}
